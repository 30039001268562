import React from "react";

import DefaultLayout from "../../../../layouts/default";

const LEDownloads = () => (
  <DefaultLayout title="Downloads – WiCare|now LE">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/WCN-LE.svg"
                alt="WiCare|Doc Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|now LE</span>
              <span className="line">
                <span className="is-visible-desktop">&nbsp;Dezentrale</span>
                &nbsp;Leistungserfassung
                <span className="is-visible-desktop">&nbsp;auf Knopfdruck</span>
              </span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/dokumentationsloesungen/wicare-le/">Übersicht</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-le/technik/">Technik</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-le/referenzen/">
                Referenzen
              </a>
            </li>
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-le/downloads/">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top has-padding-small-bottom">
            <h2>WiCare|now LE Dokumente</h2>
          </section>

          <section>
            <div className="dlFlex has-padding-top has-padding-bottom">
              <div>
                <img className="dlimg" src="/assets/images/download/le.png" />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|now LE – Leistungserfassung auf Knopfdruck
                </h5>
                <p className="boldFliesstext">
                  Mit WiCare|now LE und dem Hauptmodul WigAgent stellt die
                  WigaSoft AG die zentrale Lösung für die Leistungserfassung im
                  Gesundheitswesen vor. Weg von einzelnen Posten hin zu
                  Prozessen. WigAgent reduziert den Aufwand für die
                  Leistungserfassung auf das absolute Minimum – bei maximaler
                  Wirkung. Diverse Zusatzmodule decken die Bedürfnisse der
                  verschiedenen Fachbereiche ab.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/wicare-le/2022_WCN-LE_screen.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-top has-padding-bottom">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/wicare-le-timerbee-story.png?auto=format&w=147"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|now LE im Zusammenspiel mit Timerbee
                </h5>
                <p className="boldFliesstext">
                  Das Claraspital Basel ersetzt sein
                  Radiologie-Informationssystem und verbindet dieses mit der
                  bewährten Leistungserfassungslösung WiCare|now LE.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/wicare-le/Timerbee_Radiologie_Anwenderbericht_Claraspital-s.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
          </section>
        </div>
            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    WiCare|now LE
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/vanadium/downloads/">
                    Downloads
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default LEDownloads;
